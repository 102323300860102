import React from "react"

import SEO from "../components/seo"
import LayoutsComponent from '../layouts/LayoutsComponent';
import VerificacionTelefono from '../components/pages/VerificacionTelefono'


const VerificacionTelefonoPage = ({ pageContext, location }) => (

  <LayoutsComponent
    layout={pageContext.layout}
    broker={pageContext.broker}
    pageContext={pageContext}
    location={location}
    companyName={pageContext.company}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <VerificacionTelefono companyName={pageContext.company} 
        title="Verificación de Telefóno"
        parametro="telefono"
        leyenda=""
        />
      </>
    )}
  />
)

export default VerificacionTelefonoPage
